
import Vue, {PropType} from "vue";
import {ConfigurationDataFlat} from "kistenkonfigurator/build/property/configuration";
import {AlternateRenderingPropertyRoot} from "kistenkonfigurator/build/product/AlternateRenderingProductModel";
import {ProductModelFactory} from "../../model/product/ProductModelFactory";
import {
  getProductOverviewImage,
  getProductUrl,
} from "../../model/ProductBusinessLogic";
import {ProductDTO} from "../../model/rest/data-contracts";
import ProductConfiguratorDialog from "../admin/product/ProductConfiguratorDialog.vue";

export default Vue.extend({
  components: {ProductConfiguratorDialog},
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    product: {
      type: Object as PropType<ProductDTO>,
      default: () => ProductModelFactory.createEmptyOverviewProduct(),
      required: true,
    },
    configuration: {
      type: Object as PropType<ConfigurationDataFlat>,
      default: () => ({}),
      required: false,
    },
    /**
     * Allows to override the current product image. This can be used, if a component user likes to display
     * an image that is currently not available on the server. E.g. if an image is generated from the configurator.
     * This is most likely an DataURL.
     */
    productImageUrl: {
      type: String as PropType<string>,
      default: null,
      required: false,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    isShowInModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      propertyRoot: AlternateRenderingPropertyRoot.PRODUCT_MODEL,
    };
  },
  computed: {
    productImageAltText(): string {
      return this.product.name;
    },
  },
  mounted(): void {
    this.imageTag().onerror = () => this.handleImageError();
  },
  methods: {
    imageTag(): HTMLImageElement {
      return this.$refs!.imageTag! as any;
    },
    getProductUrl(): string {
      return getProductUrl(this as any, this.product, this.configuration, this.quantity, this.viewOnly);
    },
    defaultProductImage(): string {
      return getProductOverviewImage(this.$axios.defaults.baseURL!, this.product);
    },
    handleImageError(): void {
      if (this.imageTag().src !== this.defaultProductImage()) {
        this.imageTag().src = this.defaultProductImage();
      }
    },
    handleLinkClick(e: Event): void {
      // The order position composite uses a placeholder product to represent the folder icon.
      // Since this placeholder is non-clickable, it must be excluded.
      if (this.product.articleNumber === "") {
        e.preventDefault();
        return;
      }

      if (this.isShowInModal) {
        e.preventDefault();
        this.openModal();
      }
    },
    openModal(): void {
      (this.$refs.productConfiguratorDialog as any).open(this.product, this.configuration);
    },
  },
});
