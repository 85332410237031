
import Vue from "vue";
import {ValidationProvider} from "vee-validate";
import {ValidationInfo} from "../../../util/ui/ui-util";

export default Vue.extend({
  name: "ValidatedField",
  components: {
    ValidationProvider,
  },
  props: {
    /**
     * Is used for the id attribute.
     */
    name: {
      type: String,
      default: () => "",
      required: true,
    },
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: String,
      default: () => "required",
    },
    tooltip: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "text",
    },
    value: {
      type: [Number, String],
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    step: {
      type: String,
      default: () => null,
    },
    focus: {
      type: Boolean,
      default: () => false,
    },
    labelColsSm: {
      type: Number,
      default: () => 4,
    },
    labelColsLg: {
      type: Number,
      default: () => 3,
    },
    showAsterisk: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onBlur(event: FocusEvent) {
      this.$emit("inputBlurred", event);
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null,
                       }: { dirty: boolean, validated: boolean, valid: any }): any {
      return dirty || validated ? valid : null;
    },
    isValid(): Promise<ValidationInfo> {
      return (this.$refs.validationProvider as any).validateWithInfo();
    },
  },
});

